import { useState, useEffect } from 'react';
import { auth, signUserOut, getAllMessages } from './firebaseConfig';
import { useNavigate, Link } from 'react-router-dom';
import { onAuthStateChanged } from '@firebase/auth';
import { Box, Flex, Heading, Stack, Text, Button } from '@chakra-ui/react';
import Loader from './components/loader/loader';

const AdminApp = () => {
  let nav = useNavigate();
  let retrieving = false;

  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const tsToDate = ts => {
    let tmp = new Date(ts);

    return `${tmp.getMonth() + 1}/${tmp.getDate()}/${tmp.getFullYear()}`;
  };

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser?.uid === undefined) {
        nav('/admin/login', { replace: true });
      } else {
        if (!retrieving) {
          retrieving = true;
          getAllMessages().then(resp => {
            setMessages(resp);
            setLoading(false);
          });
        }
      }
    });
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Flex
        w="100%"
        direction="column"
        justify="flex-start"
        align="center"
        py="2rem"
      >
        <Heading fontSize="4xl" fontWeight="extrabold" color="gray.700">
          Welcome, Dia!
        </Heading>
        <Text color="gray.500">{tsToDate(Date.now())}</Text>
        <Stack
          w="100%"
          direction="row"
          justify="center"
          align="center"
          spacing="1rem"
          mt="1.5rem"
        >
          <Button
            as={Link}
            to="edit"
            w="6rem"
            bg="green.500"
            color="white"
            border="1px solid #38A169"
            _hover={{ bg: 'transparent', color: 'green.500' }}
          >
            Website
          </Button>
          <Button
            as={Link}
            to="events"
            w="6rem"
            bg="green.500"
            color="white"
            border="1px solid #38A169"
            _hover={{ bg: 'transparent', color: 'green.500' }}
          >
            Events
          </Button>
          <Button
            w="6rem"
            bg="red.100"
            color="red.500"
            _hover={{ bg: 'red.500', color: 'white' }}
            onClick={() => {
              signUserOut();
            }}
          >
            Logout
          </Button>
        </Stack>
        <Flex
          w={['95%', '75%', '50%']}
          h="30rem"
          direction="column"
          justify="flex-start"
          align="center"
          bg="white"
          boxShadow="lg"
          rounded="lg"
          mt="2rem"
          p="1rem"
          gap="1rem"
        >
          <Heading fontSize="xl" fontWeight="extrabold" color="gray.700">
            Your messages
          </Heading>
          <Stack
            w="100%"
            h="100%"
            justify="flex-start"
            align="center"
            direction="column"
            overflowY={'scroll'}
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
            spacing=".5rem"
          >
            {messages.length ? (
              messages.map(msg => (
                <Flex
                  key={msg.id}
                  w="100%"
                  h="4rem"
                  direction="row"
                  justify="space-between"
                  align="center"
                  border="1px solid #EDF2F7"
                  rounded="lg"
                  px=".5rem"
                  _hover={{
                    bg: 'gray.100',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  as={Link}
                  to={`message/${msg.id}`}
                >
                  <Box>
                    <Heading
                      display="inline-block"
                      fontSize="md"
                      color="gray.700"
                      fontWeight="semibold"
                    >
                      {msg.name}
                    </Heading>
                  </Box>
                  <Text>{msg.email}</Text>
                  <Text>{tsToDate(msg.ts)}</Text>
                </Flex>
              ))
            ) : (
              <Text>No new messages</Text>
            )}
          </Stack>
        </Flex>
      </Flex>
    );
  }
};

export default AdminApp;
