import { useState, useEffect } from 'react';
import { onAuthStateChanged } from '@firebase/auth';
import { useNavigate, Link } from 'react-router-dom';

import { auth, getFrontendData, updateSite } from '../../../firebaseConfig';
import Loader from '../../loader/loader';
import { Flex, Heading, Textarea, Button, Box } from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';

const EditWebsite = () => {
  let fetching = false;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser?.uid === undefined) {
        nav('/admin/login', { replace: true });
      } else {
        if (!fetching) {
          fetching = true;

          getFrontendData().then(resp => {
            setData(resp);
            setLoading(false);
          });
        }
      }
    });
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Flex
        w="100%"
        direction="column"
        justify="flex-start"
        align="center"
        gap="1rem"
        py="1rem"
      >
        <Flex
          display={successfulUpdate ? 'flex' : 'none'}
          w={['95%', '75%', '50%']}
          bg="green.100"
          py="1rem"
          direction="row"
          justify="center"
          align="center"
          rounded="lg"
        >
          <Heading
            color="green.500"
            fontWeight="extrabold"
            fontSize="xl"
            textAlign="center"
          >
            Website Updated!
          </Heading>
        </Flex>
        <Heading fontSize="2xl" fontWeight="extrabold" color="gray.700">
          Website Editor
        </Heading>
        {data.map((block, i) => (
          <Flex
            key={i}
            w={['95%', '75%', '50%']}
            h="auto"
            direction="column"
            justify="flex-start"
            align="center"
            bg="white"
            boxShadow="md"
            rounded="lg"
            p="1rem"
            gap="1rem"
          >
            <Heading fontSize="xl" fontWeight="extrabold" color="gray.700">
              {block.title}
            </Heading>
            <Textarea
              h="15rem"
              type="text"
              focusBorderColor={'green.500'}
              value={block.content}
              onChange={event => {
                let tmp = [...data];
                tmp[i].content = event.target.value;
                setData(tmp);
              }}
            ></Textarea>
          </Flex>
        ))}
        <Box>
          <Button
            as={Link}
            to="/admin"
            bg="transparent"
            color="green.500"
            border="1px solid #38A169"
            _hover={{ bg: 'green.500', color: 'white' }}
            mr="1rem"
          >
            <FiChevronLeft size="1.5rem" />
          </Button>
          <Button
            bg="green.500"
            color="white"
            border="1px solid #38A169"
            _hover={{ bg: 'transparent', color: 'green.500' }}
            onClick={() => {
              updateSite(data).then(() => {
                setSuccessfulUpdate(true);
              });
            }}
          >
            Save
          </Button>
        </Box>
      </Flex>
    );
  }
};

export default EditWebsite;
