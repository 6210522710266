import {
  Button,
  Flex,
  Image,
  Link,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Box,
  DrawerHeader,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { FiMenu } from 'react-icons/fi';

import Logo from '../../assets/logo_cropped.png';

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <Flex
      w="100%"
      h="auto"
      px="2rem"
      py=".75rem"
      direction="row"
      justify="space-between"
      align="center"
      mb="2rem"
    >
      <Image src={Logo} w="4.25rem" />
      <Flex
        display={['none', 'none', 'flex']}
        direction="row"
        justify="flex-end"
        align="center"
        gap="1.5rem"
      >
        <Link href="#about" color="gray.700" fontWeight="bold">
          About Me
        </Link>
        <Link href="#work" color="gray.700" fontWeight="bold">
          My Work
        </Link>
        <Link href="#contact" color="gray.700" fontWeight="bold">
          Contact
        </Link>
        <Link href="#events" color="gray.700" fontWeight="bold">
          Events
        </Link>
        <Button
          bg="green.500"
          color="white"
          _hover={{
            bg: 'white',
            color: 'green.500',
            border: '1px solid #38A169',
          }}
          onClick={() => {
            window.location.replace('https://www.etsy.com/shop/TailgateMinis');
          }}
        >
          Shop Now
        </Button>
      </Flex>

      <Box
        display={['block', 'block', 'none']}
        bg="gray.100"
        color="green.600"
        padding=".4rem"
        borderRadius=".5rem"
        _hover={{
          cursor: 'pointer',
          bg: 'green.600',
          color: 'white',
        }}
      >
        <FiMenu onClick={onOpen} size="1.75rem" />
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Flex
              w="100%"
              h="3rem"
              direction="row"
              justify="flex-start"
              align="center"
              _hover={{ bg: 'gray.200', cursor: 'pointer' }}
              rounded="xl"
              mb=".5rem"
              px="1rem"
              fontSize="lg"
              onClick={event => {
                event.preventDefault();
                window.location.replace('/#about');
                onClose();
              }}
            >
              About
            </Flex>
            <Flex
              w="100%"
              h="3rem"
              direction="row"
              justify="flex-start"
              align="center"
              _hover={{ bg: 'gray.200', cursor: 'pointer' }}
              rounded="xl"
              mb=".5rem"
              px="1rem"
              fontSize="lg"
              onClick={event => {
                event.preventDefault();
                window.location.replace('/#work');
                onClose();
              }}
            >
              My Work
            </Flex>
            <Flex
              w="100%"
              h="3rem"
              direction="row"
              justify="flex-start"
              align="center"
              _hover={{ bg: 'gray.200', cursor: 'pointer' }}
              rounded="xl"
              mb=".5rem"
              px="1rem"
              fontSize="lg"
              onClick={event => {
                event.preventDefault();
                window.location.replace('/#contact');
                onClose();
              }}
            >
              Contact
            </Flex>
            <Flex
              w="100%"
              h="3rem"
              direction="row"
              justify="flex-start"
              align="center"
              _hover={{ bg: 'gray.200', cursor: 'pointer' }}
              rounded="xl"
              mb=".5rem"
              px="1rem"
              fontSize="lg"
              onClick={event => {
                event.preventDefault();
                window.location.replace('/#events');
                onClose();
              }}
            >
              Events
            </Flex>
            <Flex
              w="100%"
              h="3rem"
              direction="row"
              justify="flex-start"
              align="center"
              _hover={{ bg: 'gray.200', cursor: 'pointer' }}
              rounded="xl"
              mb=".5rem"
              px="1rem"
              fontSize="lg"
              onClick={event => {
                window.location.replace(
                  'https://www.etsy.com/shop/TailgateMinis'
                );
              }}
            >
              Browse collection
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default Header;
