import { Flex, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Flex
      w="100%"
      mt="3rem"
      direction="column"
      justify="flex-start"
      align="center"
      mb=".75rem"
    >
      <Flex justify="center" align="center">
        <Text display="inline-block">© 2022, TailgateMinis</Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
