import { Box, Flex, Text, Stack, Heading } from '@chakra-ui/react';

import { AiFillStar } from 'react-icons/ai';

const HeroBanner = () => {
  return (
    <Flex
      w="100%"
      h={['37rem', '40rem']}
      direction={['column', 'column', 'row']}
      justify="center"
      align="center"
      gap="2rem"
    >
      <Flex
        h="100%"
        w={['100%', '100%', '60%', '50%']}
        direction="column"
        justify="center"
        align={['center', 'center', 'flex-end']}
      >
        <Flex
          w="20rem"
          h={['25rem', '30rem']}
          direction="column"
          justify="flex-start"
          align="center"
          rounded="xl"
          boxShadow="0 0 1.5rem .2rem rgba(0, 0, 0, .1)"
          bg="white"
        >
          <Box
            w="100%"
            h="60%"
            roundedTop="xl"
            bgImage="url('/castle.png')"
            bgSize="contain"
            bgPosition="center"
            bgRepeat="no-repeat"
            my="1rem"
          ></Box>
          <Text fontSize="2xl" fontWeight="extrabold" color="gray.700">
            Medieval Castle Kit
          </Text>
          <Stack direction="row">
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
          </Stack>
          <Text py="1rem" textAlign="center" fontSize="lg">
            "So much fun to make, it is absolutely adorable!!!"
          </Text>
        </Flex>
      </Flex>
      <Flex
        h="100%"
        w={['100%', '100%', '40%', '50%']}
        direction="column"
        justify={['flex-start', 'flex-start', 'center']}
      >
        <Heading
          color="gray.700"
          fontSize={['5xl', '5xl', '5xl', '5xl', '6xl']}
          fontWeight="extrabold"
          maxW={['100%', '100%', '75%', '75%', '65%', '55%']}
          textAlign={['center', 'center', 'left']}
          lineHeight={['shorter', 'normal']}
        >
          Fine,{' '}
          <Text display="inline-block" color="green.500">
            laser-cut
          </Text>{' '}
          miniature kits
        </Heading>
      </Flex>
    </Flex>
  );
};

export default HeroBanner;
