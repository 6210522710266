import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormLabel,
  FormControl,
  Input,
  Heading,
  Text,
  Textarea,
  Stack,
  Button,
} from '@chakra-ui/react';

import { useState, useEffect } from 'react';
import { onAuthStateChanged } from '@firebase/auth';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { auth, deleteMessage, getMessageInfo } from '../../../firebaseConfig';

import Loader from '../../loader/loader';

const ManageMessage = () => {
  let { id } = useParams(),
    fetching = false;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const nav = useNavigate();

  const tsToDate = ts => {
    let tmp = new Date(ts);

    return `${
      tmp.getMonth() + 1
    }/${tmp.getDate()}/${tmp.getFullYear()} @ ${tmp.getHours()}:${tmp.getMinutes()}:${tmp.getSeconds()}`;
  };

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser?.uid === undefined) {
        nav('/admin/login', { replace: true });
      } else {
        if (!fetching) {
          fetching = true;

          getMessageInfo(id).then(resp => {
            setData(resp);
            setLoading(false);
          });
        }
      }
    });
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Flex
        w="100%"
        direction="column"
        justify="flex-start"
        align="center"
        py="2rem"
        gap="1rem"
      >
        <Heading
          textAlign="center"
          fontSize="lg"
          fontWeight="extrabold"
          color="gray.700"
        >
          Message ID: {id}
          <Text textAlign="center" fontSize="sm" fontWeight="normal">
            Created: {tsToDate(data.ts)}
          </Text>
        </Heading>
        <Flex
          w={['95%', '75%', '50%']}
          h="auto"
          direction="column"
          justify="flex-start"
          align="center"
          bg="white"
          boxShadow="lg"
          p="1rem"
          rounded="xl"
          gap="1.5rem"
        >
          <FormControl id="name" w="80%">
            <FormLabel>Sender</FormLabel>
            <Input type="text" value={data.name} isDisabled={true} />
          </FormControl>
          <FormControl id="name" w="80%">
            <FormLabel>Email</FormLabel>
            <Input type="text" value={data.email} isDisabled={true} />
          </FormControl>
          <FormControl id="name" w="80%">
            <FormLabel>Message</FormLabel>
            <Textarea
              type="text"
              value={data.msg}
              isDisabled={true}
              h="10rem"
              _value={{ color: 'black' }}
            />
          </FormControl>
          <Stack w="80%" direction="column" spacing=".5rem" align="center">
            <Stack direction="row" align="center">
              <Button
                w="3rem"
                bg="transparent"
                color="gray.600"
                _hover={{ bg: 'gray.100' }}
                as={Link}
                to="/admin"
              >
                <ChevronLeftIcon w="1.5rem" h="1.5rem" />
              </Button>
              <Button
                w="9rem"
                bg="red.50"
                color="red.500"
                _hover={{ bg: 'red.500', color: 'white' }}
                onClick={() => {
                  deleteMessage(id);
                }}
                as={Link}
                to="/admin"
              >
                Delete Message
              </Button>
            </Stack>
            <Text
              textAlign="center"
              color="gray.600"
              lineHeight="shorter"
              fontWeight="normal"
            >
              Delete a message once you have replied to it so that it doesn't
              remain on your admin dashboard
            </Text>
          </Stack>
        </Flex>
      </Flex>
    );
  }
};

export default ManageMessage;
