import {
  Box,
  Flex,
  Divider,
  Heading,
  Text,
  Stack,
  Button,
} from '@chakra-ui/react';
import { AiFillStar } from 'react-icons/ai';

const MyWork = () => {
  return (
    <>
      <Flex
        id="work"
        w="100%"
        direction="row"
        justify="space-between"
        align="center"
        mt="1.5rem"
      >
        <Divider w={['25%', '25%', '35%']} orientation="horizontal" />
        <Heading fontSize="4xl" color="gray.700" fontWeight="extrabold">
          My Work
        </Heading>
        <Divider w={['25%', '25%', '35%']} orientation="horizontal" />
      </Flex>
      <Flex
        w="100%"
        px="1rem"
        py="2rem"
        h="auto"
        direction="row"
        justify="center"
        align="flex-start"
        flexWrap="wrap"
        gap="2rem"
      >
        <Flex
          w="20rem"
          minW="10rem"
          h="26rem"
          direction="column"
          justify="flex-start"
          align="center"
          rounded="xl"
          boxShadow="0 0 1rem .1rem rgba(0, 0, 0, .1)"
          bg="white"
        >
          <Box
            w="100%"
            h="60%"
            roundedTop="xl"
            bgImage="url('/bed-clear.png')"
            bgSize="contain"
            bgPosition="center"
            bgRepeat="no-repeat"
            my="1rem"
          ></Box>
          <Text fontSize="2xl" fontWeight="extrabold" color="gray.700">
            Miniature Bed Kit
          </Text>
          <Stack direction="row">
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
          </Stack>
          <Button
            mt="1rem"
            bg="green.500"
            color="white"
            _hover={{
              bg: 'white',
              color: 'green.500',
              border: '1px solid #38A169',
            }}
            onClick={() => {
              window.location =
                'https://www.etsy.com/listing/810966104/quarter-inch-round-top-painted-bed-kit';
            }}
          >
            Buy Now
          </Button>
        </Flex>
        <Flex
          w="20rem"
          minW="10rem"
          h="26rem"
          direction="column"
          justify="flex-start"
          align="center"
          rounded="xl"
          boxShadow="0 0 1rem .1rem rgba(0, 0, 0, .1)"
          bg="white"
        >
          <Box
            w="100%"
            h="60%"
            roundedTop="xl"
            bgImage="url('/chair-clear.png')"
            bgSize="contain"
            bgPosition="center"
            bgRepeat="no-repeat"
            my="1rem"
          ></Box>
          <Text fontSize="2xl" fontWeight="extrabold" color="gray.700">
            Miniature Chair Kit
          </Text>
          <Stack direction="row">
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
          </Stack>
          <Button
            mt="1rem"
            bg="green.500"
            color="white"
            _hover={{
              bg: 'white',
              color: 'green.500',
              border: '1px solid #38A169',
            }}
            onClick={() => {
              window.location =
                'https://www.etsy.com/listing/707186978/quarter-scale-miniature-chair-7-kit';
            }}
          >
            Buy Now
          </Button>
        </Flex>
        <Flex
          w="20rem"
          minW="10rem"
          h="26rem"
          direction="column"
          justify="flex-start"
          align="center"
          rounded="xl"
          boxShadow="0 0 1rem .1rem rgba(0, 0, 0, .1)"
          bg="white"
        >
          <Box
            w="100%"
            h="60%"
            roundedTop="xl"
            bgImage="url('/name-clear.png')"
            bgSize="contain"
            bgPosition="center"
            bgRepeat="no-repeat"
            my="1rem"
          ></Box>
          <Text
            textAlign="center"
            fontSize="2xl"
            fontWeight="extrabold"
            color="gray.700"
          >
            NAME 50th Anniversary
          </Text>
          <Stack direction="row">
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
            <AiFillStar size="1.35rem" color="gray.700" />
          </Stack>
          <Button
            mt="1rem"
            bg="green.500"
            color="white"
            _hover={{
              bg: 'white',
              color: 'green.500',
              border: '1px solid #38A169',
            }}
          >
            Buy Now
          </Button>
        </Flex>
      </Flex>
      <Flex w="100%" h="3rem" direction="row" justify="center" align="center">
        <Button
          bg="transparent"
          color="green.500"
          border="1px solid #38A169"
          _hover={{
            bg: 'green.500',
            color: 'white',
          }}
          onClick={() => {
            window.location = 'https://www.etsy.com/shop/TailgateMinis';
          }}
        >
          View More
        </Button>
      </Flex>
    </>
  );
};

export default MyWork;
