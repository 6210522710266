import { useState, useEffect } from 'react';
import { onAuthStateChanged } from '@firebase/auth';
import { useNavigate, Link } from 'react-router-dom';

import {
  addEvent,
  auth,
  deleteEvent,
  getAllEvents,
} from '../../../firebaseConfig';
import Loader from '../../loader/loader';
import {
  Flex,
  Heading,
  Text,
  Button,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Stack,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
} from '@chakra-ui/react';
import { FiChevronLeft, FiX } from 'react-icons/fi';

const NewEventModal = ({ isOpen, onClose, setData, setSuccessfulUpdate }) => {
  const [info, setInfo] = useState({ name: '', date: '', desc: '' });

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Event</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="column" spacing="1rem">
              <FormControl id="name">
                <FormLabel>Event Name</FormLabel>
                <Input
                  type="text"
                  placeholder="NAME Convention"
                  focusBorderColor={'green.500'}
                  errorBorderColor={'red.200'}
                  onChange={event => {
                    setInfo({ ...info, name: event.target.value });
                  }}
                />
              </FormControl>
              <FormControl id="date">
                <FormLabel>Event Date</FormLabel>
                <Input
                  type="text"
                  placeholder="08/04/2023"
                  focusBorderColor={'green.500'}
                  errorBorderColor={'red.200'}
                  onChange={event => {
                    setInfo({ ...info, date: event.target.value });
                  }}
                />
              </FormControl>
              <FormControl id="desc">
                <FormLabel>Short Description</FormLabel>
                <Input
                  type="text"
                  placeholder="A very cool event!"
                  focusBorderColor={'green.500'}
                  errorBorderColor={'red.200'}
                  onChange={event => {
                    setInfo({ ...info, desc: event.target.value });
                  }}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="green.500"
              color="white"
              border="1px solid #38A169"
              _hover={{ bg: 'transparent', color: 'green.500' }}
              mr="1rem"
              onClick={() => {
                addEvent(info.name, info.date, info.desc).then(() => {
                  getAllEvents().then(resp => {
                    setData(resp);
                    onClose();
                    setSuccessfulUpdate(true);
                  });
                });
              }}
            >
              Add Event
            </Button>
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const ManageEvents = () => {
  let fetching = false;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const nav = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser?.uid === undefined) {
        nav('/admin/login', { replace: true });
      } else {
        if (!fetching) {
          fetching = true;

          getAllEvents().then(resp => {
            setData(resp);
            setLoading(false);
          });
        }
      }
    });
  }, []);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Flex
        w="100%"
        direction="column"
        justify="flex-start"
        align="center"
        gap="1rem"
        py="1rem"
      >
        <Flex
          display={successfulUpdate ? 'flex' : 'none'}
          w={['95%', '75%', '50%']}
          bg="green.100"
          py="1rem"
          direction="row"
          justify="center"
          align="center"
          rounded="lg"
        >
          <Heading
            color="green.500"
            fontWeight="extrabold"
            fontSize="xl"
            textAlign="center"
          >
            Events Updated!
          </Heading>
        </Flex>
        <Heading fontSize="2xl" fontWeight="extrabold" color="gray.700">
          Manage Events
        </Heading>
        <Flex
          w={['95%', '75%', '50%']}
          direction="column"
          justify="flex-start"
          align="center"
          h="auto"
          bg="white"
          boxShadow="md"
          rounded="lg"
          py="1.5rem"
          px="1rem"
          gap="1rem"
        >
          {data.map(event => (
            <Flex
              key={event.id}
              w="100%"
              border="1px solid #EDF2F7"
              h="3.5rem"
              rounded="lg"
              direction="row"
              justify="space-between"
              align="center"
              px="1rem"
            >
              <Heading fontSize="lg" fontWeight="extrabold" color="gray.700">
                {event.name}
              </Heading>
              <Text>{event.date}</Text>
              <Text>{event.desc}</Text>
              <Button
                bg="red.50"
                color="red.500"
                _hover={{ bg: 'red.500', color: 'white' }}
                onClick={() => {
                  deleteEvent(event.id).then(() => {
                    getAllEvents().then(resp => {
                      setData(resp);
                      onClose();
                    });
                  });
                }}
              >
                <FiX />
              </Button>
            </Flex>
          ))}
        </Flex>
        <Box>
          <Button
            as={Link}
            to="/admin"
            bg="transparent"
            color="green.500"
            border="1px solid #38A169"
            _hover={{ bg: 'green.500', color: 'white' }}
            mr="1rem"
          >
            <FiChevronLeft size="1.5rem" />
          </Button>
          <Button
            bg="green.500"
            color="white"
            border="1px solid #38A169"
            _hover={{ bg: 'transparent', color: 'green.500' }}
            onClick={() => {
              onOpen();
            }}
          >
            Add Event
          </Button>
        </Box>
        <NewEventModal
          isOpen={isOpen}
          onClose={onClose}
          setData={setData}
          setSuccessfulUpdate={setSuccessfulUpdate}
        />
      </Flex>
    );
  }
};

export default ManageEvents;
