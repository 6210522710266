import { useState } from 'react';
import { Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

const AboutMe = ({ long, short }) => {
  const [expanded, setExpansion] = useState(false);

  return (
    <Flex
      id="about"
      w="100%"
      h="auto"
      direction="column"
      justify="flex-start"
      align="center"
      gap="1rem"
      mt={['1rem', '.5rem', '0']}
    >
      <Flex w="100%" direction="row" justify="space-between" align="center">
        <Divider w={['25%', '25%', '35%']} orientation="horizontal" />
        <Heading fontSize="4xl" color="gray.700" fontWeight="extrabold">
          About Me
        </Heading>
        <Divider w={['25%', '25%', '35%']} orientation="horizontal" />
      </Flex>
      <Text
        display={expanded ? 'none' : 'inline-block'}
        w={['90%', '90%', '75%', '60%']}
        fontSize="lg"
        textAlign="center"
      >
        {short}
      </Text>
      <Text
        display={expanded ? 'inline-block' : 'none'}
        w={['90%', '90%', '75%', '60%']}
        fontSize="lg"
        textAlign="center"
      >
        {long}
      </Text>
      <Button
        color="gray.700"
        bg="transparent"
        onClick={() => {
          setExpansion(expanded ? false : true);
        }}
      >
        Read More <FiChevronRight />
      </Button>
    </Flex>
  );
};

export default AboutMe;
