import { Flex, Divider, Heading, Text, Image, Avatar } from '@chakra-ui/react';

const EtsyProfile = () => {
  return (
    <>
      <Flex
        w="100%"
        direction="row"
        justify="space-between"
        align="center"
        mt="1.7rem"
        mb="2rem"
      >
        <Divider w={['20%', '25%', '35%']} orientation="horizontal" />
        <Heading fontSize="4xl" color="gray.700" fontWeight="extrabold">
          Loved By All
        </Heading>
        <Divider w={['20%', '25%', '35%']} orientation="horizontal" />
      </Flex>
      <Flex
        w="100%"
        direction={['column', 'column', 'row']}
        justify={['center', 'center', 'flex-start']}
        align="center"
        gap={['1rem', '2rem']}
        mt={['2rem', '0']}
      >
        <Flex
          w={['100%', '100%', '50%']}
          direction="column"
          justify="center"
          align={['center', 'center', 'flex-end']}
        >
          <Image
            src="/etsy-text-profile.png"
            w="18rem"
            boxShadow="0 0 1rem .1rem rgba(0, 0, 0, .1)"
            rounded="xl"
          />
        </Flex>
        <Flex
          w={['100%', '100%', '50%']}
          direction="column"
          justify="center"
          align={['center', 'center', 'flex-start']}
        >
          <Heading
            fontSize={['5xl', '6xl']}
            fontWeight="extrabold"
            color="gray.700"
            lineHeight="shorter"
          >
            <Text display="inline-block" color="green.500">
              1,000+
            </Text>{' '}
            Sales
          </Heading>
          <Heading
            fontSize={['5xl', '6xl']}
            fontWeight="extrabold"
            color="gray.700"
            lineHeight="shorter"
          >
            <Text display="inline-block" color="green.500">
              5
            </Text>{' '}
            Stars
          </Heading>
        </Flex>
      </Flex>
    </>
  );
};

const Testimonial = ({ text, name, slug }) => {
  return (
    <Flex
      w={['90%', '90%', '45rem']}
      h={['18rem', '18rem', '15rem']}
      bg="white"
      rounded="xl"
      boxShadow="0 .5rem 1rem .1rem rgba(0, 0, 0, .1)"
      direction="row"
      justify="flex-start"
      align="center"
      p="1rem"
    >
      <Flex
        direction="column"
        justify={['space-between', 'space-between', 'space-evenly']}
        align="flex-start"
        w="70%"
        h="100%"
      >
        <Text fontSize="lg">"{text}"</Text>
        <Heading fontSize="lg" fontWeight="extrabold">
          {name}{' '}
          <Text display="inline-block" fontSize="lg" fontWeight="light">
            - Verified customer
          </Text>
        </Heading>
      </Flex>
      <Flex w="30%" direction="column" justify="center" align="center">
        <Avatar name="Kathy" src={`/` + slug + `.jpg`} size={['lg', 'xl']} />
      </Flex>
    </Flex>
  );
};

const Testimonials = () => {
  return (
    <Flex
      direction="column"
      justify="flex-start"
      align="center"
      mt="3rem"
      gap="1.5rem"
      mb="5rem"
    >
      <Testimonial
        text="Though I haven’t yet built the house, I am out there buy ing
  furniture for it! These are lovely pieces, and look like they will
  be, if not simple (they are tiny after all), then reasonably easy to
  put together. Fun, anyway!"
        name="Kathy"
        slug="kathy-profile"
      />
      <Testimonial
        text="The kit is absolutely adorable and the instructions are very detailed and complete. I can not wait to put it together!"
        name="Kim"
        slug="kim-profile"
      />
      <Testimonial
        text="I love these cain-bottom chairs. Almost like my 2 real chairs handed down through my family for 150 years. They look beautiful with my table. Thanks for fast shipping, too."
        name="Shirley"
        slug="shirley-profile"
      />
    </Flex>
  );
};

const SocialProof = () => {
  return (
    <>
      <EtsyProfile />
      <Testimonials />
    </>
  );
};

export default SocialProof;
