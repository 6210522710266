import { ChakraProvider } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { Global } from '@emotion/react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';

import theme from './theme';

import App from './App';
import AdminApp from './AdminApp';
import Login from './components/admin/login/login';
import ManageMessage from './components/admin/mesage/manageMessage';
import EditWebsite from './components/admin/copy/editWebsite';
import ManageEvents from './components/admin/events/manageEvents';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Jokerman';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/jokerman.woff') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
  />
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Fonts />
        <Routes>
          <Route index element={<App />} />
          <Route path="admin" element={<AdminApp />} />
          <Route path="admin/login" element={<Login />} />
          <Route path="admin/message/:id" element={<ManageMessage />} />
          <Route path="admin/edit" element={<EditWebsite />} />
          <Route path="admin/events" element={<ManageEvents />} />
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  </StrictMode>
);

serviceWorker.unregister();
