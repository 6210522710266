import { useState, useEffect } from 'react';

import { auth, signUserIn } from '../../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from '@firebase/auth';

import {
  Flex,
  Heading,
  Stack,
  FormControl,
  Input,
  FormLabel,
  Button,
  Spinner,
} from '@chakra-ui/react';

const Login = () => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailIsInvalid, setEmailValidity] = useState(false);
  const [passwordIsInvalid, setPasswordValidity] = useState(false);

  let nav = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      if (currentUser?.uid !== undefined) {
        nav('/admin', { replace: true });
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <Flex
        display={loading ? 'none' : 'flex'}
        w="100%"
        h={['-webkit-fill-available', '100vh']}
        direction="column"
        justify="center"
        align="center"
      >
        <Stack
          w={['90%', '90%', '25rem']}
          direction="column"
          spacing="1rem"
          justify="center"
          align="center"
        >
          <Heading fontSize="2xl" fontWeight="extrabold">
            Login to your account
          </Heading>
          <Flex
            w="100%"
            h="auto"
            direction="column"
            justify="flex-start"
            align="center"
            bg="white"
            rounded="xl"
            boxShadow="0 0 1.5rem .2rem rgba(0, 0, 0, .1)"
            py="1rem"
            px="2rem"
          >
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Your Email"
                focusBorderColor={'green.500'}
                errorBorderColor={'red.200'}
                isInvalid={emailIsInvalid}
                onChange={event => {
                  setEmail(event.target.value);
                }}
              />
            </FormControl>
            <FormControl id="password" mt="1rem">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Your Password"
                focusBorderColor={'green.500'}
                errorBorderColor={'red.200'}
                isInvalid={passwordIsInvalid}
                onChange={event => {
                  setPassword(event.target.value);
                }}
              />
            </FormControl>
            <Button
              mt="1.8rem"
              bg="green.500"
              color="white"
              px="2rem"
              _hover={{
                bg: 'white',
                color: 'green.500',
                border: '1px solid #38A169',
              }}
              onClick={() => {
                signUserIn(email, password);
              }}
            >
              Login
            </Button>
          </Flex>
        </Stack>
      </Flex>
      <Flex
        display={loading ? 'flex' : 'none'}
        w="100%"
        h={['-webkit-fill-available', '100vh']}
        direction="row"
        justify="center"
        align="center"
      >
        <Spinner
          thickness=".3rem"
          speed=".65s"
          emptyColor="gray.200"
          color="green.500"
          size="xl"
        />
      </Flex>
    </>
  );
};

export default Login;
