import {
  Flex,
  Divider,
  Heading,
  Input,
  FormControl,
  Button,
  Text,
  Spinner,
  Textarea,
} from '@chakra-ui/react';

import { CheckCircleIcon } from '@chakra-ui/icons';

import { useState } from 'react';
import { submitContactForm } from '../../firebaseConfig';

const Contact = () => {
  const [submittedForm, setSubmittedForm] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');

  const [nameValidity, setNameValidity] = useState(false);
  const [emailValidity, setEmailValidity] = useState(false);
  const [msgValidity, setMsgValidity] = useState(false);

  const submit = () => {
    setProcessing(true);

    if (!name) {
      setNameValidity(true);
      setProcessing(false);
      return;
    } else if (!email) {
      setEmailValidity(true);
      setProcessing(false);
      return;
    } else if (!msg) {
      setMsgValidity(true);
      setProcessing(false);
      return;
    } else {
      submitContactForm(name, email, msg).then(() => {
        setSubmittedForm(true);
      });
    }
  };

  return (
    <>
      <Flex
        id="contact"
        w="100%"
        direction="row"
        justify="space-between"
        align="center"
        mt="1.5rem"
        mb="2rem"
      >
        <Divider w={['25%', '30%', '35%']} orientation="horizontal" />
        <Heading fontSize="4xl" color="gray.700" fontWeight="extrabold">
          Contact
        </Heading>
        <Divider w={['25%', '30%', '35%']} orientation="horizontal" />
      </Flex>
      <Flex direction="row" justify="center" align="flex-start">
        <Flex
          display={submittedForm ? 'flex' : 'none'}
          h="20rem"
          w={['90%', '80%', '50%']}
          direction="column"
          justify="center"
          align="center"
          bg="white"
          rounded="xl"
          boxShadow="0 0 1rem .1rem rgba(0, 0, 0, .1)"
          px="2rem"
          py="1rem"
        >
          <CheckCircleIcon w="4rem" h="4rem" color="green.400" mb=".5rem" />
          <Heading
            textAlign="center"
            fontSize={['2xl', '2xl', '3xl']}
            fontWeight="extrabold"
            color="gray.700"
            lineHeight="short"
          >
            Success
          </Heading>
          <Text
            w={['95%', '85%', '75%']}
            fontSize="lg"
            textAlign="center"
            color="gray.600"
            lineHeight="short"
          >
            Your message has been successfully submitted! I will get back to you
            as soon as I can. Thank you!
          </Text>
        </Flex>
        <Flex
          display={submittedForm ? 'none' : 'flex'}
          h="auto"
          w={['90%', '80%', '50%']}
          direction="column"
          justify="flex-start"
          align="center"
          bg="white"
          rounded="xl"
          boxShadow="0 0 1rem .1rem rgba(0, 0, 0, .1)"
          px="2rem"
          py="1rem"
          gap="1rem"
        >
          <Flex
            direction="column"
            justify="flex-start"
            align="center"
            mb=".5rem"
          >
            <Heading
              textAlign="center"
              fontSize={['2xl', '2xl', 'xl']}
              fontWeight="extrabold"
              color="gray.700"
              lineHeight="short"
            >
              Need to get in touch?
            </Heading>
            <Text
              w={['95%', '85%', '75%']}
              fontSize="md"
              textAlign="center"
              color="gray.500"
              lineHeight="short"
            >
              Just fill out this form and I will get back to you at the
              specified Email as soon as I can!
            </Text>
          </Flex>
          <FormControl id="name">
            <Input
              type="text"
              placeholder="Your first & last name"
              focusBorderColor={'green.500'}
              errorBorderColor={'red.200'}
              isDisabled={processing}
              isInvalid={nameValidity}
              onChange={event => {
                setName(event.target.value);
              }}
            />
          </FormControl>
          <FormControl id="email">
            <Input
              type="email"
              placeholder="Your Email"
              focusBorderColor={'green.500'}
              errorBorderColor={'red.200'}
              isDisabled={processing}
              isInvalid={emailValidity}
              onChange={event => {
                setEmail(event.target.value);
              }}
            />
          </FormControl>
          <FormControl id="message">
            <Textarea
              h="10rem"
              type="text"
              placeholder="What is on your mind?"
              focusBorderColor={'green.500'}
              errorBorderColor={'red.200'}
              isDisabled={processing}
              isInvalid={msgValidity}
              onChange={event => {
                setMsg(event.target.value);
              }}
            />
          </FormControl>
          <Button
            mt=".5rem"
            bg="green.500"
            color="white"
            _hover={{
              bg: 'white',
              color: 'green.500',
              border: '1px solid #38A169',
            }}
            onClick={() => {
              submit();
            }}
          >
            {!processing ? (
              'Send Message'
            ) : (
              <Spinner mx="2rem" thickness=".2rem" size="md" />
            )}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default Contact;
