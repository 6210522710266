import { React, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

import Header from './components/header/header';
import HeroBanner from './components/hero/hero';
import AboutMe from './components/about/about';
import MyWork from './components/work/work';
import SocialProof from './components/socialProof/socialProof';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import Loader from './components/loader/loader';

import { getAllEvents, getFrontendData } from './firebaseConfig';
import Events from './components/events/events';

const App = () => {
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getFrontendData().then(respData => {
      getAllEvents().then(respEvents => {
        setData(respData);
        setEvents(respEvents);
      });
    });
  }, []);

  if (data[0] !== undefined) {
    console.log(data);
    return (
      <Box
        bgImage="url('/opacity-corrected-logo.png')"
        bgSize="contain"
        bgPosition={['top', 'center']}
        bgRepeat="no-repeat"
        backgroundAttachment="fixed"
      >
        <Header />
        <HeroBanner />
        <AboutMe long={data[0].content} short={data[1].content} />
        <MyWork />
        <SocialProof />
        <Events events={events} />
        <Contact />
        <Footer />
      </Box>
    );
  } else {
    return <Loader />;
  }
};

export default App;
