import { initializeApp } from 'firebase/app';

import { getAuth, signInWithEmailAndPassword, signOut } from '@firebase/auth';

import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  collection,
  updateDoc,
  addDoc,
} from '@firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAgwFuAs8zczi0AN9Ndg3MYKbM4sI1hc6s',
  authDomain: 'tailgateminis.firebaseapp.com',
  projectId: 'tailgateminis',
  storageBucket: 'tailgateminis.appspot.com',
  messagingSenderId: '446946791219',
  appId: '1:446946791219:web:882d152f10946810f57846',
  measurementId: 'G-KR54EG47R6',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const getFrontendData = async () => {
  let snap = await getDoc(doc(db, 'home', 'fields'));

  if (snap.exists()) {
    return snap.data().data;
  }
};

const signUserIn = async (email, password) => {
  await signInWithEmailAndPassword(auth, email, password);
};

const signUserOut = async () => {
  await signOut(auth);
};

const submitContactForm = async (name, email, msg) => {
  const res = await addDoc(collection(db, 'messages'), {
    name: name,
    email: email,
    msg: msg,
    ts: Date.now(),
  });

  return res?.id;
};

const getAllMessages = async () => {
  let ret = [];
  const qs = await getDocs(collection(db, 'messages'));

  qs.forEach(doc => {
    ret.push({ id: doc.id, ...doc.data() });
  });

  return ret;
};

const getMessageInfo = async id => {
  const snap = await getDoc(doc(db, 'messages', id));

  if (snap.exists()) {
    return snap.data();
  }
};

const deleteMessage = async id => {
  await deleteDoc(doc(db, 'messages', id));
  return;
};

const updateSite = async data => {
  await updateDoc(doc(db, 'home', 'fields'), {
    data: data,
  });
  return;
};

const addEvent = async (name, date, desc) => {
  const res = await addDoc(collection(db, 'events'), {
    name: name,
    date: date,
    desc: desc,
  });

  return res?.id;
};

const deleteEvent = async id => {
  await deleteDoc(doc(db, 'events', id));
  return;
};

const getAllEvents = async () => {
  let ret = [];
  const qs = await getDocs(collection(db, 'events'));

  qs.forEach(doc => {
    ret.push({ id: doc.id, ...doc.data() });
  });

  return ret;
};

export {
  auth,
  getFrontendData,
  signUserIn,
  signUserOut,
  submitContactForm,
  getAllMessages,
  getMessageInfo,
  updateSite,
  deleteMessage,
  addEvent,
  deleteEvent,
  getAllEvents,
};
