import {
  Divider,
  Flex,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

const Events = ({ events }) => {
  return (
    <Flex
      id="events"
      w="100%"
      h="auto"
      direction="column"
      justify="flex-start"
      align="center"
      gap="1rem"
      mb="3rem"
    >
      <Flex
        w="100%"
        direction="row"
        justify="space-between"
        align="center"
        mb="1.5rem"
      >
        <Divider w={['10%', '16%', '35%']} orientation="horizontal" />
        <Heading
          fontSize={('2xl', '3xl', '4xl')}
          color="gray.700"
          fontWeight="extrabold"
        >
          Upcoming Events
        </Heading>
        <Divider w={['10%', '16%', '35%']} orientation="horizontal" />
      </Flex>
      <TableContainer
        w={['90%', '90%', '75%', '60%']}
        px="2rem"
        py="1rem"
        bg="white"
        rounded="xl"
        boxShadow="0 0 1rem .1rem rgba(0, 0, 0, .1)"
      >
        <Table variant="simple" textAlign="center" size={('sm', 'md', 'lg')}>
          <TableCaption>
            {events.length >= 1
              ? 'This list is maintained and updated by me!'
              : 'There are no current upcoming events'}
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Event</Th>
              <Th>About it</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {events.map(event => (
              <Tr key={event.id}>
                <Th>{event.name}</Th>
                <Th>{event.desc}</Th>
                <Th>{event.date}</Th>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default Events;
