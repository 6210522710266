import { Flex, Spinner } from '@chakra-ui/react';

const Loader = () => {
  return (
    <Flex w="100%" h="100vh" direction="row" justify="center" align="center">
      <Spinner
        thickness=".3rem"
        speed=".65s"
        emptyColor="gray.200"
        color="green.500"
        size="xl"
      />
    </Flex>
  );
};

export default Loader;
